import React from 'react';
import Header from '../../Header/Header'

export default class NotificationsListPage extends React.Component{
    render(){
        return(
            <div>
                <Header/>
            </div>
        )
    }
}